import styled from "styled-components"

export const StyledHomeHeader = styled.section`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  margin-top: 0px;
`

export const Background = styled.div`
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: fill;
  filter: blur(1px);
  div {
    height: 100vh;
  }
  ::after {
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
`

export const Text = styled.div`
  color: ${({ theme }) => theme.lighter};
  border: 1px solid ${({ theme }) => theme.primary};
  width: 80vw;

  text-align: center;
  padding: 0.5rem;
  text-transform: uppercase;
  z-index: 2;
  font-size: ${({ theme }) => theme.fontL};
  p:nth-child(2) {
    font-style: italic;
    text-transform: none;
    &:after,
    &:before {
      content: "-";
      margin: 10px;
    }
  }
`
