import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StyledHeaderPhoneNumber } from "./HeaderPhoneNumber.styled"

const HeaderPhoneNumber: React.FC = () => {
  const contactData = useStaticQuery(graphql`
    query {
      gcms {
        contactDetails {
          contactDetails
        }
      }
    }
  `)
  const {
    phone,
  }: { phone: string } = contactData.gcms.contactDetails[0].contactDetails

  const numberPhone = phone.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3")

  return (
    <StyledHeaderPhoneNumber>
      <a href={`tel:+48${phone}`}>{numberPhone}</a>
    </StyledHeaderPhoneNumber>
  )
}

export default HeaderPhoneNumber
