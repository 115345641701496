import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StyledContactData } from "./ContactData.styled"

interface ContactDataProps {
  fullHeight?: boolean
}

const ContactData: React.FC<ContactDataProps> = ({ fullHeight = false }) => {
  const contactData = useStaticQuery(graphql`
    query {
      gcms {
        contactDetails {
          contactDetails
        }
      }
    }
  `)

  const { mail, phone } = contactData.gcms.contactDetails[0].contactDetails

  return (
    <StyledContactData fullHeight={fullHeight}>
      <p>
        tel.: <a href={`tel:${phone}`}>{phone}</a>
      </p>
      <p>
        e-mail.: <a href={`mailto:${mail}`}>{mail}</a>
      </p>
    </StyledContactData>
  )
}

export default ContactData
