import { createGlobalStyle } from "styled-components"

const galleryCSS: string = require("react-image-gallery/styles/css/image-gallery.css")

export const GlobalStyles = createGlobalStyle`
  ${galleryCSS}
  html, body {
    margin: 0;
    padding: 0;
  }
  *, *::after, *::before {
    box-sizing: border-box;
  }
  .bodySite {
    grid-area: bodySite;
  }
  footer {
    grid-area: footer;
  }
  .gridOne {
    grid-area: gridOne;
  }
  .gridTwo {
    grid-area: gridTwo;
  }
  body {
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.secondary};
    text-rendering: optimizeLegibility;
    font-family: 'Quicksand', sans-serif;
    font-weight: lighter;
  }
  h2 {
    font-weight: lighter;
    text-transform: uppercase;
    margin-top: 30px;
  }
  img {
    height: auto; 
    width: 10rem;
  }
  a {
      color: ${({ theme }) => theme.primary};
      font-style: italic;
      text-decoration: none;

      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  `
