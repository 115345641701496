import styled from "styled-components"

export const LayoutStyles = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  text-rendering: optimizeLegibility; 
*/
  display: grid;
  grid-template-rows: auto 200px;
  .bodySite {
    /* background-color: ${({ theme }) => theme.primary};
    z-index: -10; */
    background-color: transparent;
  }
`

export const GridStyled = styled.div`
  /* display: grid;
  grid-template-rows: auto 20vh;
  grid-template-areas:
    "bodySite"
    "footer"; */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  :first-child {
    background-color: ${({ theme }) => theme.lighter};
  }

  /* @media (min-width: 1000px) {
    grid-template-columns: 1fr 4fr 2fr 1fr;
    grid-template-areas:
      "bodySite gridOne gridTwo bodySite"
      "footer footer footer footer";
  } */
`

export const MainStyled = styled.div`
  background-color: ${({ theme }) => theme.lighter};
  z-index: -10;

  flex: 1 0 auto;
`
