import { useEffect, MouseEvent } from "react"
import { FalseyValue } from "styled-components"

export const useOnClickOutside = (
  ref: React.RefObject<HTMLDivElement>,
  setClose: (a: boolean) => void
) => {
  useEffect(() => {
    function listener(event: any) {
      if (!ref.current || ref.current.contains(event.target)) return
      setClose(false)
    }
    document?.addEventListener("mousedown", e => listener(e))

    return () => {
      document?.removeEventListener("mousedown", e => listener(e))
    }
  }, [ref, setClose])
}
