import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StyledFooter } from "./Footer.styled"
import ContactData from "../contact-data"

const Footer: React.FC = () => {
  const contactData = useStaticQuery(graphql`
    query {
      gcms {
        contactDetails {
          contactDetails
        }
      }
    }
  `)
  const { companyName } = contactData.gcms.contactDetails[0].contactDetails

  return (
    <StyledFooter>
      <h3>{companyName}</h3>
      <ContactData />
    </StyledFooter>
  )
}
export default Footer
