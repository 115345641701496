import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { StyledMenu } from "./Menu.styled"
import Logo from "../../logo"
import { QueryMenuProps } from "../../../types/interface"

interface MenuProps {
  open: boolean
}

const Menu: React.FC<MenuProps> = ({ open }) => {
  const data: QueryMenuProps = useStaticQuery(graphql`
    query {
      gcms {
        menus {
          nazwaStrony
          url
        }
      }
    }
  `)
  const menuNames = data.gcms.menus

  return (
    <StyledMenu open={open}>
      <Logo />
      {menuNames.map(({ nazwaStrony, url }, index) => (
        <Link to={url} activeClassName="active" key={`${nazwaStrony}-${index}`}>
          {nazwaStrony}
        </Link>
      ))}
    </StyledMenu>
  )
}
export default Menu
