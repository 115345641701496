import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { QueryImg } from "../types/interface"

const ImgDiv = styled.div`
  width: 125px;
  height: 125px;
  margin: 2rem auto 6rem;
  img {
    object-fit: contain;
  }
`

const Image: React.FC = () => {
  const { logo }: QueryImg = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-m.png" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <ImgDiv>
      <Img fixed={logo.childImageSharp.fixed} />
    </ImgDiv>
  )
}

export default Image
