import React, { useState, useRef, ReactNode } from "react"
import { useOnClickOutside } from "../hooks/hooks"

import { ThemeProvider } from "styled-components"
import { GlobalStyles } from "../../styles/global"
import { theme } from "../../styles/theme"
import { LayoutStyles, GridStyled, MainStyled } from "./Layout.styled"

import { Burger, Menu, HeaderPhoneNumber } from "../header"
import Footer from "../footer"
import Cookie from "../cookie-consent"

interface LayoutProps {
  readonly isMainPage?: boolean
  readonly children?: ReactNode | ReactNode[]
}

const Layout: React.FC<LayoutProps> = ({ children, isMainPage }) => {
  const [open, setOpen] = useState(false)
  const node = useRef<HTMLDivElement>(null)
  const setClose = () => setOpen((prev: boolean) => (prev = true && false))
  useOnClickOutside(node, setClose)
  return (
    <ThemeProvider theme={theme}>
      <LayoutStyles>
        <GlobalStyles />
        <div ref={node}>
          <Burger open={open} setOpen={setOpen} />
          {isMainPage && <HeaderPhoneNumber />}
          <Menu open={open} />
        </div>
        <GridStyled>
          <MainStyled className="bodySite">{children}</MainStyled>
          <Footer />
        </GridStyled>
        <Cookie />
      </LayoutStyles>
    </ThemeProvider>
  )
}

export default Layout
