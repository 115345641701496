import styled from "styled-components"

export const CookieStyles = styled.div`
  .CookieConsent {
    align-items: baseline;
    background: ${({ theme }) => theme.secondaryRGBA(0.9)};
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    left: 0px;
    position: fixed;
    width: 100%;
    z-index: 999;
    bottom: 0px;
    padding: ${({ theme }) => theme.marginS};
    p {
      text-align: center;
    }
  }
  .cookie-button {
    border: 1px solid ${({ theme }) => theme.primary};
    text-transform: uppercase;
    background-color: transparent;
    color: ${({ theme }) => theme.primary};
    min-width: 8rem;
    min-height: 2.5rem;
    margin: ${({ theme }) => theme.marginS};
    :hover {
      color: ${({ theme }) => theme.lighter};
      background-color: ${({ theme }) => theme.primary};
      border-color: ${({ theme }) => theme.lighter};
    }
  }
`
