import styled from "styled-components"

export const StyledHeaderPhoneNumber = styled.div`
  position: absolute;
  top: 5%;
  right: 2rem;
  display: flex;
  cursor: pointer;
  padding: 0;
  margin: 0;
  z-index: 1;
  border-bottom: 1px solid ${({ theme }) => theme.primary};
  line-height: 30px;
  &:hover {
    border-bottom-color: ${({ theme }) => theme.lighter};
  }
  a {
    color: ${({ theme }) => theme.lighter};
    font-size: ${({ theme }) => theme.fontM};
    border: none;
    font-style: normal;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    &:focus {
      outline: none;
      border: none;
      text-decoration: none;
    }
  }
`
