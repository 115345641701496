import styled from "styled-components"

export const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.lighter};
  padding: ${({ theme }) => theme.marginXL};
  text-align: center;
  bottom: -20px;
  padding-bottom: -5px;
  max-height: 25vh;
  position: relative;
  h3 {
    font-weight: lighter;
  }
  p {
    margin: 5px;
  }
`
