import React from "react"
import { Link } from "gatsby"
import { CookieStyles } from "./Cookie.styled"
import CookieConsent from "react-cookie-consent"

const Cookie: React.FC = () => {
  return (
    <CookieStyles>
      <CookieConsent
        location="bottom"
        buttonText="Rozumiem"
        cookieName="amberia-site-cookie"
        buttonClasses="cookie-button"
        disableStyles
        cookieSecurity
        expires={182}
      >
        <p>
          Ta strona korzysta z plików cookie. Zapoznaj się z naszą{" "}
          <Link to="/polityka-prywatnosci">polityką prywatności</Link>.
        </p>
      </CookieConsent>
    </CookieStyles>
  )
}
export default Cookie
