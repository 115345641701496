import styled from "styled-components"

interface StyledMenuProps {
  readonly open: boolean
}

export const StyledMenu = styled.nav<StyledMenuProps>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: top;
  background: ${({ theme }) => theme.backgroundLight};
  height: 100vh;
  min-width: 20rem;
  text-align: left;
  padding: 6rem 2rem;
  position: fixed;
  top: 0;
  left: -1000px;
  box-shadow: 2px 1px 10px 0px rgba(145, 145, 145, 0.69);
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(1000px)" : "translateX(0)")};
  z-index: 10;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    box-shadow: none;
  }

  a {
    font-size: ${({ theme }) => theme.fontS};
    text-transform: uppercase;
    padding: 0.7rem 0;
    letter-spacing: 0.5rem;
    color: ${({ theme }) => theme.secondary};
    text-decoration: none;
    transition: color 0.3s linear;
    font-style: normal;

    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: ${({ theme }) => theme.fontM};
    }

    &:hover {
      text-decoration: none;
    }
  }
  .active {
    text-decoration: none;
    &:before {
      content: "";
      position: relative;
      padding-left: 5px;
      width: 100%;
      border-left: 0.1rem solid ${({ theme }) => theme.primary};
    }
  }
  img {
    max-width: 100px;
  }
`
