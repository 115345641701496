import React from "react"
import { StyledHomeHeader, Background, Text } from "./HomeHeader.styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { QueryImg } from "../../../types/interface"

const HomeHeader: React.FC = () => {
  const { baner }: QueryImg = useStaticQuery(graphql`
    query {
      baner: file(relativePath: { eq: "baner-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 40) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <StyledHomeHeader>
      <Text>
        <p>komfortowy relaks</p>
        <p>na</p>
        <p>mazurach</p>
      </Text>
      <Background>
        <Img loading="eager" fluid={baner.childImageSharp.fluid} />
      </Background>
    </StyledHomeHeader>
  )
}

export default HomeHeader
